import React, { useEffect, useState } from "react";
import loadingGif from "../assets/images/loading.gif";

export default function Supporter() {
  const [supporterData, setSupporterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const associationId = 1;

  useEffect(() => {
    const fetchSupporterData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-supporters?associationId=${associationId}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setSupporterData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSupporterData();
  }, [associationId]);

  if (error) {
    return console.log("Error:" + { error });
  }
  return (
    <>
      {isLoading ? (
        <div
          className="text-center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img src={loadingGif} alt="loading" />
        </div>
      ) : supporterData.length === 0 ? (
        <div className="text-center text-gray-500"></div>
      ) : (
        <>
          <section className="py-6 border-t border-b border-gray-100 dark:border-gray-700">
            <div className="container relative">
              <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                {supporterData.map((item, index) => {
                  return (
                    <div className="mx-auto py-4" key={index}>
                      <img
                        src={`https://besnoktaotizm.holatoggle.com.tr/images/supporters/${item.logoName}`}
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
