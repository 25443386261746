import React from "react";
import "../../node_modules/react-modal-video/css/modal-video.css";
import ulusalPdf from "../assets/images/Otizm_Spektrum_Bozuklugu_Olan_Bireylere_Yonelik_II_Ulusal_Eylem_Plani.pdf";

export default function UlusalEylemPlani() {
  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl text-xl font-medium">
            Beş Nokta Otizm Yaşam Derneği
            <br />
            2023-2030 Ulusal Eylem Planı
          </h3>
        </div>
        <div className="container relative">
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={`${ulusalPdf}#page=1&zoom=50`}
              width="100%"
              height="100%"
              type="application/pdf"
              style={{ objectFit: "contain", border: "none" }}
              title="PDF Viewer"
            />
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <a
              href={ulusalPdf}
              download
              style={{
                padding: "10px 20px",
                backgroundColor: "#f97316",
                color: "white",
                textDecoration: "none",
                borderRadius: "5px",
              }}
            >
              İndirmek için tıklayınız
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
