import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Navlight from "../components/navlight";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function DetailTwo() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [error, setError] = useState(null);
  const params = useParams();
  const id = params.id;
  const associationId = 1;

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/event?eventId=${id}&associationId=${associationId}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setEventData(data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchEventData();
  }, [associationId]);

  const handleMovePrev = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex + eventData.eventPhotos.length - 1) %
        eventData.eventPhotos.length
    );
  };

  const handleMoveNext = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % eventData.eventPhotos.length
    );
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setisOpen(true);
  };

  return (
    <>
      <Navlight />
      <section
        className="relative table w-full py-32 lg:py-40 bg-[url('../../assets/images/bg-inner.jpg')] bg-no-repeat bg-cover"
        style={{ backgroundPosition: "center" }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl  font-bold text-white">
              {eventData?.title}
            </h3>
            <h2 className="inline text-2xl text-white">
              {eventData?.description}
            </h2>
            <h2 className="inline font-semibold text-white/60">
              {eventData?.date
                ? new Date(eventData?.date).toLocaleDateString("tr-TR", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })
                : new Date().toLocaleDateString("tr-TR", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
            </h2>
          </div>
        </div>
      </section>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 justify-center">
            <div className="relative z-2 duration-500 sm:-mt-[200px] -mt-[140px] m-0">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
              >
                <Masonry>
                  {eventData?.eventPhotos?.map((photo, index) => (
                    <div className="picture-item p-1" key={index}>
                      <div className="group relative block overflow-hidden rounded-xl duration-500">
                        <button
                          onClick={() => handleImageClick(index)}
                          className="lightbox duration-500 group-hover:scale-105"
                        >
                          <img
                            src={`https://besnoktaotizm.holatoggle.com.tr/images/events/${photo}`}
                            className="w-full"
                            alt={`event-${index}`}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </div>

        {/* Lightbox */}
        {isOpen && (
          <Lightbox
            mainSrc={`https://besnoktaotizm.holatoggle.com.tr/images/events/${eventData?.eventPhotos[currentImageIndex]}`}
            prevSrc={`https://besnoktaotizm.holatoggle.com.tr/images/events/${
              eventData?.eventPhotos[
                (currentImageIndex + eventData?.eventPhotos?.length - 1) %
                  eventData.eventPhotos.length
              ]
            }`}
            nextSrc={`https://besnoktaotizm.holatoggle.com.tr/images/events/${
              eventData?.eventPhotos[
                (currentImageIndex + 1) % eventData?.eventPhotos?.length
              ]
            }`}
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </section>
      {/* 
      <section className="container relative md:mt-24 mt-16">
        <div className="flex mx-auto justify-center">
          <div className="lg:w-2/3 md:w-full text-center">
            <h4 className="text-3xl leading-normal font-semibold mb-6">
              {eventData.description}
            </h4>
          </div>
        </div>
      </section> */}

      <Footer />
      <Switcher />
    </>
  );
}
