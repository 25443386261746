import React from "react";
import { Link } from "react-router-dom";

import bannerImg from "../assets/images/hero.png";
import bannerImg2 from "../assets/images/adhv6582-810x1080.jpeg";
import bannerLogo from "../assets/images/logo.png";

import Navbar from "../components/navbar";
import Otizm from "../components/otizm";
import About from "../components/about";
// import Team from "../components/team";
import Blog from "../components/blog";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { TypeAnimation } from "react-type-animation";
import UlusalEylemPlani from "../components/ulusal-eylem-plani";
import Supporter from "../components/supporters";
import PhotoGallery from "../components/photo-gallery";

export default function Index() {
  return (
    <>
      <Navbar />
      <section className="relative overflow-hidden md:flex md:h-screen py-36 items-center bg-gray-50 dark:bg-slate-800">
        <div className="container relative">
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
            <div className="">
              {/* <h5 className="font-semibold leading-normal text-[22px] mt-6">
                Sende Görmezden Gelme!
              </h5>
              <h5 className="font-semibold leading-normal text-[22px] mt-6">
                <TypeAnimation
                  sequence={[
                    "Bir gün engelli olabilir İnsan.",
                    1000,
                    "Bir gün engelli olabiliriz.",
                    1000,
                    "Bir gün engelli olabilirSİN.",
                    1000,
                  ]}
                  wrapper="span"
                  speed={10}
                  className="typewrite"
                  repeat={Infinity}
                />
              </h5> */}

              <h5
                className="md:text-5xl md:leading-normal text-3xl leading-normal font-bold mt-3 text-red-500"
                style={{ color: "#d90604" }}
              >
                Farklıyız <br /> Farkındayız
              </h5>

              {/* <p className="text-slate-400 dark:text-white/60 text-[17px] max-w-xl mt-4">
                Unutmayalım her sağlıklı insan aslında birer engelli adayıdır
              </p> */}

              <div className="relative mt-6">
                <Link
                  to="/iletişim"
                  className="py-2 px-5 inline-flex items-center font-medium tracking-wide border align-middle duration-500 text-[15px] text-center bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 text-white rounded-xl"
                >
                  İletişim <i className="mdi mdi-arrow-right ms-2"></i>
                </Link>
              </div>
            </div>

            <div className="relative lg:ms-6">
              <div className="relative z-1">
                <img
                  src={bannerImg}
                  alt=""
                  className="w-full h-auto object-cover"
                />
              </div>

              <div className="absolute inset-0 flex justify-center items-center">
                <img
                  src={bannerLogo}
                  alt=""
                  className="absolute inset-0 w-full h-full object-cover z-0"
                  style={{ opacity: "0.5" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative overflow-hidden md:py-24 py-16">
        <Otizm />
        <About />
        <UlusalEylemPlani />
        <PhotoGallery gridclass="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 mt-4 gap-[30px]" />

        <section className="relative md:py-24 py-16 md:pt-1 pt-1">
          <div className="container relative">
            <div className="grid grid-cols-1 justify-center">
              <div className="relative z-1">
                <div className="grid lg:grid-cols-12 grid-cols-1 items-center justify-center gap-16">
                  <div className="lg:col-span-5">
                    <img
                      src={bannerImg2}
                      className="rounded-xl shadow-lg w-full"
                      alt=""
                    />
                  </div>
                  <div className="lg:col-span-2"></div>
                  <div className="lg:col-span-5 flex items-center text-white">
                    <div>
                      <p className="max-w-xl font-medium mb-2 md:text-3xl mt-4">
                        "PRİM Psixoloji Reabilitasiya Ve İnkisaf Merkezi İle
                        Kardeş Okul Projemiz."
                      </p>
                      <br />
                      <h3 className="md:text-2xl md:leading-normal text-2xl leading-normal font-semibold mt-2">
                        Ayşegül AYDIN <br /> - BAŞKAN
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-orange-500/50 to-orange-500"></div>
        </section>

        {/* <Team /> */}
        <Blog />
      </section>
      <Supporter />
      <Footer />
      <Switcher />
    </>
  );
}
