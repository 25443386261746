import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadingGif from "../assets/images/loading.gif";

import Navlight from "../components/navlight";
import ContactUs from "../components/contactUs";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

export default function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const associationId = 1;
  const pageSize = 12;

  useEffect(() => {
    const fetchBlogData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-blogs?associationId=${associationId}&page=${currentPage}&pageSize=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setBlogData(data.blogs);
        setTotalPages(data.totalPages);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogData();
  }, [associationId, currentPage]);

  if (error) {
    return console.log("Error:" + { error });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Navlight />
      <section
        className="relative table w-full py-32 lg:py-40 bg-[url('../../assets/images/bg-inner.jpg')] bg-no-repeat bg-cover"
        style={{ backgroundPosition: "center" }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
              Blog Yazıları
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Beş Nokta Otizm Yaşam Derneği</Link>
            </li>
            <li className="inline-block text-[18px] text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right align-middle"></i>
            </li>
            <li
              className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Blog Yazıları
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          {isLoading ? (
            <div
              className="text-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={loadingGif} alt="loading" />
            </div>
          ) : blogData.length === 0 ? (
            <div className="text-center text-gray-500">
              <p>Henüz blog yazısı eklenmedi.</p>
            </div>
          ) : (
            <>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                {blogData.map((item, index) => (
                  <div className="group" key={index}>
                    <div className="relative overflow-hidden rounded-xl shadow-md dark:shadow-gray-700">
                      <img
                        src={`https://besnoktaotizm.holatoggle.com.tr/images/blog/${item.fileName}`}
                        className=""
                        alt={item.title}
                      />
                      <div className="absolute inset-0 bg-gradient-to-b to-slate-900 from-transparent opacity-0 group-hover:opacity-100 duration-500"></div>
                      <div className="absolute start-4 bottom-4 opacity-0 group-hover:opacity-100 flex items-center duration-500">
                        <div className="ms-2">
                          <Link
                            to=""
                            className="text-white hover:text-orange-500 text-[15px]"
                          >
                            {item.author} -{" "}
                            {item?.date
                              ? new Date(item?.date).toLocaleDateString(
                                  "tr-TR",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )
                              : "01 Ocak, 0001"}
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="p-4">
                      <Link
                        to={`/blog-detay/${item.id}/${item.title.replace(
                          /\s+/g,
                          "-"
                        )}`}
                        className="title text-lg font-medium hover:text-orange-500 duration-500"
                      >
                        {item.title}
                      </Link>

                      <p
                        className="text-slate-400 dark:text-white/60 mt-3"
                        dangerouslySetInnerHTML={{
                          __html:
                            item.content.split(" ").slice(0, 20).join(" ") +
                            "...",
                        }}
                      ></p>

                      <div className="mt-3">
                        <Link
                          to={`/blog-detay/${item.id}/${item.title}`}
                          className="hover:text-orange-500"
                        >
                          Devamını Oku{" "}
                          <i className="mdi mdi-arrow-right align-middle"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                <div className="md:col-span-12 text-center">
                  <nav aria-label="Page navigation example">
                    <ul className="inline-flex items-center -space-x-px">
                      <li>
                        <Link
                          to="#"
                          onClick={() =>
                            handlePageChange(
                              currentPage > 1 ? currentPage - 1 : 1
                            )
                          }
                          className="w-8 h-8 inline-flex text-sm justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                        >
                          <i className="mdi mdi-chevron-left text-[20px] rtl:rotate-180"></i>
                        </Link>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index}>
                          <Link
                            to="#"
                            onClick={() => handlePageChange(index + 1)}
                            className={`w-8 h-8 inline-flex text-sm justify-center items-center ${
                              currentPage === index + 1
                                ? "text-white bg-orange-500"
                                : "text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                            }`}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link
                          to="#"
                          onClick={() =>
                            handlePageChange(
                              currentPage < totalPages
                                ? currentPage + 1
                                : totalPages
                            )
                          }
                          className="w-8 h-8 inline-flex text-sm justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                        >
                          <i className="mdi mdi-chevron-right text-[20px] rtl:rotate-180"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          )}
        </div>
        <ContactUs />
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
