import { Route, Routes } from "react-router-dom";
import Index from "./pages";
import AboutUs from "./pages/aboutus";
import Blog from "./pages/blogs";
import BlogDetails from "./pages/blog-detail";
import ContactUs from "./pages/contactus";
import ClassicFive from "./pages/classic-five";
import DetailTwo from "./pages/portfolio-detail-two";
import ManagementTeam from "./pages/management-team";
import InstructorTeam from "./pages/instructorTeam";
import EventReports from "./pages/event-reports";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/hakkımızda" element={<AboutUs />} />
        <Route path="/yönetim-kadromuz" element={<ManagementTeam />} />
        <Route path="/eğitmen-kadromuz" element={<InstructorTeam />} />
        <Route path="/faaliyet-raporlarımız" element={<EventReports />} />
        <Route path="/bloglar" element={<Blog />} />
        <Route path="/blog-detay" element={<BlogDetails />} />
        <Route path="/blog-detay/:id/:title" element={<BlogDetails />} />
        <Route path="/iletişim" element={<ContactUs />} />
        <Route path="/faaliyetlerimiz" element={<ClassicFive />} />
        <Route path="/faaliyet-detay" element={<DetailTwo />} />
        <Route path="/faaliyet-detay/:id/:title" element={<DetailTwo />} />
      </Routes>
    </div>
  );
}

export default App;
