import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import phone from "../assets/images/icons/call-center-support-service.svg";
import mail from "../assets/images/icons/mail-send-envelope.svg";
import location from "../assets/images/icons/location-pin-3.svg";

import Navlight from "../components/navlight";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { FiHexagon } from "../assets/icons/vander";

export default function ContactUs() {
  const [contactInfo, setContactInfo] = useState({
    phoneNumbers: [],
    emails: [],
    address: [],
  });

  const associationId = 1;

  useEffect(() => {
    async function fetchContactInfo() {
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-contacts?associationId=${associationId}`
        );
        const data = await response.json();

        const phoneNumbers = data.filter((item) => item.type === 2);
        const emails = data.filter((item) => item.type === 1);
        const address = data.filter((item) => item.type === 4);

        setContactInfo({
          phoneNumbers,
          emails,
          address,
        });
      } catch (error) {
        console.error("Error fetching contact info:", error);
      }
    }

    fetchContactInfo();
  }, []);

  return (
    <>
      <Navlight />
      <section
        className="relative table w-full py-32 lg:py-40 bg-[url('../../assets/images/bg-inner.jpg')] bg-no-repeat bg-cover"
        style={{ backgroundPosition: "center" }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/80 to-black"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
              İletişim
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Beş Nokta Otizm Yaşam Derneği</Link>
            </li>
            <li className="inline-block text-[18px] text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right align-middle"></i>
            </li>
            <li
              className="inline-block capitalize text-[14px] duration-500 ease-in-out text-white"
              aria-current="page"
            >
              İletişim
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative md:pb-24 pb-16">
        <div className="container relative lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-28 w-28 fill-orange-500/5 mx-auto rotate-[30deg]" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-orange-500 rounded-xl transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                  <img src={phone} className="h-8" alt="Phone Icon" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title text-lg font-medium">Telefon</h5>
                {contactInfo.phoneNumbers.map((phoneNumber, index) => (
                  <div key={index} className="mt-5">
                    <Link
                      to={`tel:${phoneNumber.value}`}
                      className="text-orange-500 hover:text-orange-500 after:bg-orange-500 transition duration-500"
                    >
                      {phoneNumber.value}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-28 w-28 fill-orange-500/5 mx-auto rotate-[30deg]" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-orange-500 rounded-xl transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                  <img src={mail} className="h-8" alt="Mail Icon" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title text-lg font-medium">E-Posta</h5>
                {contactInfo.emails.map((email, index) => (
                  <div key={index} className="mt-5">
                    <Link
                      to={`mailto:${email.value}`}
                      className="text-orange-500 hover:text-orange-500 after:bg-orange-500 transition duration-500"
                    >
                      {email.value}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            {/* Adres */}
            <div className="text-center px-6">
              <div className="relative overflow-hidden text-transparent -m-3">
                <FiHexagon className="h-28 w-28 fill-orange-500/5 mx-auto rotate-[30deg]" />
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-orange-500 rounded-xl transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                  <img src={location} className="h-8" alt="Location Icon" />
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="title text-lg font-medium">Adres</h5>
                {contactInfo.address.map((address, index) => (
                  <div key={index} className="mt-5">
                    <p className="dark:text-white/60 mt-3">{address.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11260.398834671867!2d28.743939696278886!3d40.98333010507691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa170171a0f21%3A0x4912222c7e2285a1!2sAvc%C4%B1lar%20Belediyesi%20G%C3%BClten%20Nakipo%C4%9Flu%20Engelli%20Ya%C5%9Fam%20Merkezimizi!5e1!3m2!1str!2str!4v1733418666433!5m2!1str!2str"
              width="100%"
              height="450"
              title="besnoktamap"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
      <Switcher />
    </>
  );
}
