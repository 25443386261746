import React from "react";
import "../../node_modules/react-modal-video/css/modal-video.css";
import headerImg1 from "../assets/images/dscn1374-1488x1116.jpg";

export default function Otizm() {
  return (
    <>
      <div className="container relative md:pb-24 pb-16">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="lg:col-span-12 md:col-span-12">
            <div className="relative">
              <div className="relative flex justify-center">
                <img
                  src={headerImg1}
                  style={{ width: 700 }}
                  className="rounded-xl shadow dark:shadow-gray-700"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="lg:col-span-12 md:col-span-12">
            <div className="lg:ms-5">
              <h3
                className="mb-4 md:text-2xl text-xl font-medium"
                style={{ textAlign: "center" }}
              >
                Otizm Nedir? Otizm Ne Demek?
              </h3>

              <p
                className="dark:text-white"
                style={{ textAlign: "justify", lineHeight: 2 }}
              >
                Otizm spektrum bozukluğu ya da bilinen adıyla otizm erken
                çocukluk döneminde başlayan, ciddi bir gelişimsel bozukluktur.
                Genellikle, oturma, yürüme gibi motor becerilerin gelişiminde,
                boy ve kilo alımında bir sorun görülmemektedir. Otizmli
                çocukların dış görünüşleri diğer çocuklardan farklı değildir.
                Aksine güzel ve sağlıklı görünen çocuklardır. Bazılarının baş
                çevresi büyük olabilir. Asıl sorun, sosyal etkileşime, iletişime
                zarar veren, sınırlı ilgi alanlarına ve tekrarlanan hareketlere
                yol açan davranışlarının bulunmasıdır. Bazen belirtiler 1
                yaşından önce başlarken, bazılarında normal psiko-sosyal gelişme
                olup “mama, baba” gibi ilk kelimeler söylendikten sonra
                gelişimde gerileme, duraksama fark edilir. Otizmin beynin
                yapısını ve işleyişini etkileyen sinir sistemindeki sorunlardan
                kaynaklandığı düşünülmektedir. Bu hastalığın çocuk yetiştirme
                türleriyle ve ailenin sosyo-ekonomik düzeyiyle bir ilgisi
                bulunmamaktadır
                <br />
                <br />
                Teşhisi anne karnında mümkün olmayan otizm, kız çocuklarına
                oranla erkek çocuklarda dört kat daha fazla görülmektedir. Bunun
                nedeni tam olarak bilinmemektedir. Otizm, yaygın gelişimsel
                bozuklukların bir alt grubudur. Yaygın gelişimsel diğer
                bozukluklar ise;
                <br /> <br />
                Asperger sendromu: Dil gelişimleri otizme göre daha iyi
                olmaktadır.
                <br />
                <br />
                Ret sendromu: Sadece kız çocuklarında görülen ağır zihinsel
                geriliği ve baş çevresi küçüklüğü ile seyreden bir genetik
                hastalıktır.
                <br />
                <br />
                Dezintegratif: İlk iki yaşa kadar çocuğun gelişimi normaldir
                ancak sonrasında otizm benzeri bulgular başlamaktadır.
              </p>
              <br />
              <h3
                className="mb-4 md:text-2xl text-xl font-medium"
                style={{ textAlign: "center" }}
              >
                Otizm Belirtileri Nelerdir?
              </h3>
              <p
                className="dark:text-white"
                style={{ textAlign: "justify", lineHeight: 2 }}
              >
                Belirtilerin, küçük çocuğun umursamazlığı mı yoksa iletişim
                konusundaki isteksizliği mi olduğuna karar verilmesi zordur.
                Konuşma gecikmesi birçok sağlıklı çocukta da görülebilmektedir.
                Çocuğun bazen belirtileri gösterirken bazen de sağlıklı
                gelişiyor gibi davranması ailenin kafasını karıştırabilir.
                Otizmin bir spektrum bozukluğu olarak adlandırılması tam da bu
                yüzdendir. Hafiften ağıra doğru olan belirti şiddetleri;
                sağlıklı ama yavaş gelişen çocukla, ağır nöro psikolojik
                hastalık arasında bir yelpaze oluşturur. Uyku, besin seçme gibi
                zorluklar sıklıkla bulunabilir.
                <br /> <br />
                Otizmin en temel belirtisi, çocukların göz teması
                kuramamalarıdır. Kısa süreli, çekingen bakış, göz kontağı varmış
                gibi bakma ama karşıdakini anlamaya yönelik bakmama gibi
                durumlar olabilir. İsmi ile çağrılan çocuklar genellikle tepki
                veremez ve neredeyse tümünde konuşma problemleri vardır. Bu
                hastalar ya hiç konuşamaz veya geç veya tuhaf konuşur. Taklit
                becerileri kısıtlıdır. Bay-bay yapma, selam verme ve mimik
                kullanmak gibi taklitleri geç öğrenirler.
                <br /> <br />
                Otistik çocuklar işaret ile isteklerini gösteremez ve iletişim
                problemleri çok sıktır. İhtiyaçlarını, karşılayacak kişinin
                kolunu çekerek, onu kaldırıp götürerek, ağlayarak gösterirler.
                Yaşıtları veya büyükler ile iletişim kurmada isteksizdirler.
                Grup oyunlarına katılamaz ve oyuncaklar ile anlamlı bir şekilde
                oynayamazlar. Akranlarıyla olmak yerine kendi halinde olmayı
                tercih ederler. Bazı çocuklarda sallanma, el çırpma, kanat
                çırpma gibi “stereotipi” denilen, tekrarlayan anormal hareketler
                veya koridorda koşturma, ışığı açma-kapama gibi takıntılı
                davranışlar görülebilir. Otistik çocukların bir diğer önemli
                belirtisi temastan hoşlanmamalarıdır. Bebeklik çağında bile anne
                kucağında sakinleşmez, aksine rahatsız olurlar. Otistik çocuklar
                kendilerine ait ayrı bir dünyada yaşar. Otistik bir çocuk kucağa
                alınmayı önemsemez. İstediğine ulaşmak için herkesin kucağına
                gidebilir, yabancı algılaması yoktur. 2-3 yaşına geldiğinde
                cansız objelere daha fazla ilgi gösterirler. Sürekli ellerinde
                bir eşya tutma, objeleri dizerek oynama, arabayı ters çevirip
                tekerleğini döndürme veya yatarak arabanın dönen tekerleğini
                izleme gibi oyunları vardır. Oyuncağın bütünü yerine bir
                parçasıyla oynamayı severler. Onun için canlıyla cansız arasında
                çok fazla fark yoktur. İnsanlarla anlamlı göz kontağı kurmazlar.
                Ses, ışık ve kokuya karşı aşırı duyarlı olabilir, beklenmedik
                tepkiler verebilirler. Bazen kendisine söylenenleri papağan gibi
                tekrar ederler, ben-sen gibi zamirleri ters söylerler.
              </p>
              <br />
              <h5>
                <strong>
                  Memorial Tıbbi Yayın Kurulu tarafından hazırlanmıştır.
                </strong>
                <br />
                <strong>Güncelleme Tarihi : 28 Mart 2022</strong>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
