import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import loadingGif from "../assets/images/loading.gif";

import "react-18-image-lightbox/style.css";
export default function ClassicPortfolio({ gridclass }) {
  const [eventData, setEventData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const associationId = 1;
  const pageSize = 12;

  useEffect(() => {
    const fetchEventData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-events?associationId=${associationId}&page=${currentPage}&pageSize=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setEventData(data.events);
        setTotalPages(data.totalPages);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEventData();
  }, [associationId, currentPage]);

  if (error) {
    return console.log("Error:" + { error });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="relative">
        <div className="container-fluid relative">
          {isLoading ? (
            <div
              className="text-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={loadingGif} alt="loading" />
            </div>
          ) : eventData.length === 0 ? (
            <div className="text-center text-gray-500">
              <p>Henüz faaliyet eklenmedi.</p>
            </div>
          ) : (
            <>
              <div id="grid" className={gridclass}>
                {eventData.map((item, index) => {
                  return (
                    <div className="picture-item" key={index}>
                      <div className="group relative block overflow-hidden rounded-xl duration-500">
                        <div className="relative overflow-hidden">
                          <Link to={`/faaliyet-detay/${item.id}/${item.title}`}>
                            <img
                              src={`https://besnoktaotizm.holatoggle.com.tr/images/events/${item.eventPhotos[0]}`}
                              className="rounded-xl"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="content pt-3">
                          <h5 className="mb-1">
                            <Link
                              to={`/faaliyet-detay/${item.id}/${item.title}`}
                              className="hover:text-orange-500 duration-500 font-semibold"
                            >
                              {item.title}
                            </Link>
                          </h5>
                          <h6
                            className="text-slate-400 dark:text-white/60"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.description
                                  .split(" ")
                                  .slice(0, 15)
                                  .join(" ") + "...",
                            }}
                          ></h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Pagination */}
              <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                <div className="md:col-span-12 text-center">
                  <nav aria-label="Page navigation example">
                    <ul className="inline-flex items-center -space-x-px">
                      <li>
                        <Link
                          to="#"
                          onClick={() =>
                            handlePageChange(
                              currentPage > 1 ? currentPage - 1 : 1
                            )
                          }
                          className="w-8 h-8 inline-flex text-sm justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                        >
                          <i className="mdi mdi-chevron-left text-[20px] rtl:rotate-180"></i>
                        </Link>
                      </li>
                      {[...Array(totalPages)].map((_, index) => (
                        <li key={index}>
                          <Link
                            to="#"
                            onClick={() => handlePageChange(index + 1)}
                            className={`w-8 h-8 inline-flex text-sm justify-center items-center ${
                              currentPage === index + 1
                                ? "text-white bg-orange-500"
                                : "text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                            }`}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <Link
                          to="#"
                          onClick={() =>
                            handlePageChange(
                              currentPage < totalPages
                                ? currentPage + 1
                                : totalPages
                            )
                          }
                          className="w-8 h-8 inline-flex text-sm justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500"
                        >
                          <i className="mdi mdi-chevron-right text-[20px] rtl:rotate-180"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
