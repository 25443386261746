import React, { useState } from "react";
import { Link } from "react-router-dom";

import image1 from "../assets/images/gallery/adsz-tasarm-4-547x387.png";
import image2 from "../assets/images/gallery/ejkl6307-547x308.jpg";
import image3 from "../assets/images/gallery/img-2027-4032x3024.jpg";
import image4 from "../assets/images/gallery/img-3366-547x410.jpg";
import image5 from "../assets/images/gallery/img-666-4032x3024.jpg";
import image6 from "../assets/images/gallery/dscn1475-2000x1500.jpg";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import { FiCamera } from "../assets/icons/vander";

export default function PhotoGallery({ gridclass, container }) {
  const images = [image1, image2, image3, image4, image5, image6];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);

  const handleMovePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + images.length - 1) % images.length
    );
  };

  const handleMoveNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setisOpen(true);
  };
  const currentImage = images[currentImageIndex];

  const projectData = [
    {
      id: 0,
      image: image1,
    },
    {
      id: 1,
      image: image2,
    },
    {
      id: 2,
      image: image3,
    },
    {
      id: 3,
      image: image4,
    },
    {
      id: 4,
      image: image5,
    },
    {
      id: 5,
      image: image6,
    },
  ];

  return (
    <>
      <div className="relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3 className="mb-4 md:text-3xl text-xl font-medium">
            Beş Nokta Otizm Yaşam Derneği
          </h3>
        </div>

        <div className="container">
          <div id="grid" className={gridclass}>
            {projectData.map((item, index) => {
              return (
                <div
                  className="picture-item"
                  data-groups='["branding"]'
                  key={index}
                >
                  <div className="group relative block overflow-hidden rounded-xl duration-500">
                    <div className="relative overflow-hidden">
                      <img
                        src={item.image}
                        className="rounded-xl"
                        alt=""
                        style={{ width: 400, height: 265, objectFit: "cover" }}
                      />

                      <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-3 end-3 duration-500">
                        <Link
                          to=""
                          onClick={() => handleImageClick(item.id)}
                          className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 text-white rounded-xl lightbox"
                        >
                          <FiCamera className="h-[14px] w-[14px] align-middle" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          prevSrc={
            images[(currentImageIndex + images.length - 1) % images.length]
          }
          nextSrc={images[(currentImageIndex + 1) % images.length]}
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </>
  );
}
