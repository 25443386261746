import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import loadingGif from "../assets/images/loading.gif";

export default function Team() {
  const [teamData, setTeamData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const associationId = 1;

  useEffect(() => {
    const fetchTeamData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-management-team?associationId=${associationId}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setTeamData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTeamData();
  }, [associationId]);

  if (error) {
    return console.log("Error:" + { error });
  }

  return (
    <>
      <div className="container relative lg:mt-24 mt-16">
        {isLoading ? (
          <div
            className="text-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loadingGif} alt="loading" />
          </div>
        ) : teamData.length === 0 ? (
          <div className="text-center text-gray-500"></div>
        ) : (
          <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            {teamData.map((item, index) => {
              return (
                <div className="lg:col-span-3 md:col-span-6" key={index}>
                  <div className="group text-center">
                    <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                      <img
                        src={`https://besnoktaotizm.holatoggle.com.tr/images/team/${item.photoName}`}
                        className=""
                        alt=""
                      />
                    </div>

                    <div className="content mt-3">
                      <Link
                        to=""
                        className="text-lg font-medium hover:text-orange-500 duration-500"
                      >
                        {item.fullName}
                      </Link>
                      <p className="text-slate-400 dark:text-white/60">
                        {item.role}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
