import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import loadingGif from "../assets/images/loading.gif";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import ContactUs from "../components/contactUs";

import "../../node_modules/react-modal-video/css/modal-video.css";

export default function BlogDetails() {
  const params = useParams();
  const id = params.id;

  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState(null);
  const associationId = 1;

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/blog?blogId=${id}&associationId=${associationId}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        console.log(data);
        setBlogData(data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchBlogData();
  }, [associationId]);

  if (error) {
    return console.log("Error:" + { error });
  }

  return (
    <>
      <Navbar />
      <section className="relative pt-40 md:pb-24 pb-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="lg:w-3/5">
              <h5 className="md:text-3xl text-2xl md:leading-normal leading-normal font-medium">
                {blogData?.title
                  ? blogData?.title
                  : "Stop Worrying About Deadlines! We Got You Covered"}
              </h5>
              <div className="flex mt-5">
                <span className="font-medium">
                  <i className="mdi mdi-calendar-blank-outline me-2"></i>
                  {blogData?.date
                    ? new Date(blogData?.date).toLocaleDateString("tr-TR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })
                    : new Date().toLocaleDateString("tr-TR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                </span>
              </div>

              <div className="flex items-center mt-5">
                <div className="ms-2">
                  <Link
                    to=""
                    className="font-medium block hover:text-orange-500"
                  >
                    {blogData?.author ? blogData?.author : "Beş Nokta Otizm"}
                  </Link>
                  <span className="text-slate-400 dark:text-white/60 text-sm block">
                    Yazar
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex justify-center mt-6">
            <div className="lg:w-4/5">
              <img
                src={`https://besnoktaotizm.holatoggle.com.tr/images/blog/${blogData.fileName}`}
                className="rounded-xl mt-4"
                alt=""
              />
              <p
                className="dark:text-white/60 text-[15px] mt-10"
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: blogData.content,
                }}
              ></p>
            </div>
          </div>
        </div>

        <ContactUs />
      </section>
      <Footer />
      <Switcher />
    </>
  );
}
