import React from "react";
import "../../node_modules/react-modal-video/css/modal-video.css";
import otizm_meclis from "../assets/images/otizm_meclis.jpg";
import headerImg1 from "../assets/images/hcrh6317-711x948.jpg";
import misyon_vizyon from "../assets/images/misyon_vizyon.jpeg";

export default function About() {
  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-5">
              <div className="relative">
                <img
                  src={headerImg1}
                  className="rounded-lg shadow-lg relative"
                  alt=""
                />
              </div>
            </div>

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h3 className="mb-4 md:text-2xl text-xl font-medium">
                  Hakkımızda: Beş Nokta Otizm Yaşam Derneği
                </h3>

                <p
                  className="dark:text-white max-w-2xl mx-auto"
                  style={{ lineHeight: 2, textAlign: "justify" }}
                >
                  <strong>Beş Nokta Otizm Yaşam Derneği</strong> otizmle ilgili
                  gönüllülük ve farkındalık esasına dayanan hak mücadelesi
                  temelli bir sivil toplum örgütü ’dür. Otizmli bireylere ve
                  ailelerine eğitim destek ve bilgi vermeyi hedeflemiş uzman
                  eğitimci ve meslek elemanları topluluğundan oluşmuştur. Başta
                  İstanbul olmak üzere tüm Türkiye’de Otizmli bireyler ve
                  aileleri ile akademik, sosyal, gelişimsel ve bilgi
                  entegrasyonunun sağlanması için çalışan derneğimiz Otizmle (
                  Diğer tüm zihinsel engel grupları ile Down sendromu dahil )
                  ilgili ulusal politikaların belirlenmesinde ’de aktif rol
                  oynamaktadır. İşte bu nedenle Otizmli bireyler, aileleri ve
                  tüm toplum için diyoruz ki; Uzun yollar ilk adımla başlar bu
                  yolda hep birlik de yürüyelim….
                </p>

                <div className="relative mt-8">
                  Beş Nokta Otizm Yaşam Derneği (BENOYDER) <br />
                  Kuruluş tarihi: 17 KASIM 2015
                </div>
                <div className="relative mt-8">
                  <img
                    src={otizm_meclis}
                    alt="Benoyder"
                    style={{ width: "100px !important" }}
                    className="h-auto rounded-lg shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="lg:col-span-7 md:col-span-7">
        <div className="relative">
          <div className="relative flex justify-center">
            <img
              src={misyon_vizyon}
              className="rounded-lg shadow-lg"
              alt=""
              width={700}
            />
          </div>
        </div>
      </div>
    </>
  );
}
