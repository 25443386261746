import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadingGif from "../assets/images/loading.gif";

export default function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const associationId = 1;
  const pageSize = 3;
  useEffect(() => {
    const fetchBlogData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://associationapi.holatoggle.com.tr/api/get-blogs?associationId=${associationId}&page=1&pageSize=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Data fetching failed");
        }
        const data = await response.json();
        setBlogData(data.blogs);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogData();
  }, [associationId]);

  if (error) {
    return console.log("Error:" + { error });
  }
  return (
    <>
      {" "}
      <div className="container relative md:mt-24 mt-16">
        {isLoading ? (
          <div
            className="text-center"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={loadingGif} alt="loading" />
          </div>
        ) : blogData.length === 0 ? (
          <div className="text-center text-gray-500"></div>
        ) : (
          <>
            <div className="grid grid-cols-1 pb-6 text-center">
              <h3 className="mb-6 md:text-2xl text-xl font-medium">
                Blog'da Neler Yeni?
              </h3>

              {/* <p className="text-slate-400 dark:text-white/60 max-w-xl mx-auto">This is just a simple text made for this unique and awesome template, you can replace it with any text.</p> */}
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
              {blogData.map((item, index) => {
                return (
                  <div className="group" key={index}>
                    <div className="relative overflow-hidden rounded-xl shadow-md dark:shadow-gray-700">
                      <img
                        src={`https://besnoktaotizm.holatoggle.com.tr/images/blog/${item.fileName}`}
                        className=""
                        alt=""
                      />
                      <div className="absolute inset-0 bg-gradient-to-b to-slate-900 from-transparent opacity-0 group-hover:opacity-100 duration-500"></div>
                      <div className="absolute start-4 bottom-4 opacity-0 group-hover:opacity-100 flex items-center duration-500">
                        <div className="ms-2">
                          <Link
                            href=""
                            className="text-white hover:text-orange-500 text-[15px]"
                          >
                            {item.author} -{" "}
                            {item?.date
                              ? new Date(item?.date).toLocaleDateString(
                                  "tr-TR",
                                  {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )
                              : "01 Ocak, 0001"}
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="p-4">
                      <Link
                        to={`/blog-detay/${item.id}/${item.title}`}
                        className="title text-lg font-medium hover:text-orange-500 duration-500"
                      >
                        {item.title}
                      </Link>
                      <p
                        className="text-slate-400 dark:text-white/60 mt-3"
                        dangerouslySetInnerHTML={{
                          __html:
                            item.content.split(" ").slice(0, 20).join(" ") +
                            "...",
                        }}
                      ></p>

                      <div className="mt-3">
                        <Link
                          to={`/blog-detay/${item.id}/${item.title}`}
                          className="hover:text-orange-500"
                        >
                          Devamını Oku{" "}
                          <i className="mdi mdi-arrow-right align-middle"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}
